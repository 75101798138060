import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Image from '../../components/image'
import { Row, Col, ProductBookCard, Button, Video } from '../../components/global'
import config from '../../siteConfig'

const H116 = () => (
  <Layout>
    <SEO title="The Wire Harness Troubleshooting Trainer, H-116" />
    <div className="container container-last">
      <h1>
        The Wire Harness
        <br />
        Troubleshooting Trainer, H-116
      </h1>
      <p className="boost">
        Learn how to troubleshoot, identify and repair any type of wire harness problem.
      </p>
      <hr />
      <Row>
        <Col width="30%" align="left">
          <ProductBookCard
            title="The Wire Harness Troubleshooting Trainer, H-116"
            titleSmall={true}
            step="Hands-on Kit"
            animate={false}
            price={375.0}
          />
        </Col>
        <Col width="68%" align="left">
          <p className="demote">
            <strong>
              Before purchasing this product, make sure you own{' '}
              <Link to="/products/h-111a">The Starter Kit H-111A</Link> as it is required to own
              this kit.
            </strong>
          </p>
          <p>
            Learn to trace circuit voltages, find open connections and voltage drop problems in
            multiple circuits connected together. Learn how bad connections in the wire harness can
            affect perfectly good circuits.
          </p>
          <p>
            <Button
              href="/products/circuit-board-trainer-inventory-clearance"
              text="See Purchasing Options"
              type="secondary"
            />
          </p>
        </Col>
      </Row>
      <hr />
      <p className="boost">See The Wire Harness Troubleshooting Trainer, H-116 in action.</p>
      <Video
        title="See The Wire Harness Troubleshooting Trainer, H-116 in action"
        src="https://www.youtube.com/embed/rxwyTa2cjQg"
      />
      <hr />
      <p>
        Troubleshooting wiring harnesses can be a difficult task given all the possibilities of how
        a wire harness can fail: open circuits, voltage drops, shorts-to-ground and
        shorts-to-voltage.
      </p>
      <p>
        <strong>
          No need to ever replace a wire harness because there is a problem, instead, fix it right
          the first time!
        </strong>
      </p>
      <Row>
        <Col width="49%" align="left">
          <p>The Wire Harness Troubleshooting Trainer H-116 module contains the following:</p>
          <ul>
            <li>1 - Wire Harness Circuit Board H-PCB06</li>
            <li>1 - Student Workbook H-WB116 (replaces SM06 or S-SM06)</li>
            <li>1 - Instructor Guide H-IG116</li>
            <li>1 - Resistor Bag H-RB06 for inserting troubleshooting problems.</li>
          </ul>
        </Col>
        <Col width="49%" align="left">
          <Image file="h-116.jpg" />
        </Col>
      </Row>
      <hr />
      <h2>What Next?</h2>
      <p>
        Continue developing your troubleshooting skills and learning more about auto/truck
        electrical/electronic troubleshooting with the following live circuit troubleshooting
        trainers!
      </p>
      <ul>
        <li>
          <Link to="/products/h-200">CAN Bus Troubleshooting Trainer H-200(S)</Link>
        </li>
      </ul>
    </div>
  </Layout>
)

export default H116
